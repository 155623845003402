function initCarousel(index, item) {
    const direction = document.dir === 'ltr';
    console.log('Init carousel on document ready', index, item)
    const carousel = $(this);
    const cards = carousel.find('.cards');
    const cardWidth = carousel.find('.carousel-card-container').outerWidth();
    const carouselDotsContainer = carousel.find('.carousel-dots');
    const track = carousel.find('.track');
    const prevBtn = carousel.find('.prevBtn');
    const nextBtn = carousel.find('.nextBtn');
    const containerWidth = carousel.width();
    const bodyWidth = document.body.offsetWidth
    let allCards = item.querySelectorAll('.carousel-card-container')
    let currentIndex
    let translateX

    function setInicialIndex() {
        if (allCards.length > 6) {
            currentIndex = 4
        }
        if (allCards.length > 3 && allCards.length <= 6) {
            currentIndex = 2
        }
        if (allCards.length <= 3) {
            track.addClass('container')
        }
    }

    setInicialIndex()
    //add dots indicator
    if (allCards.length >= 8) {
        for (let i = 0; i < 3; i++) {
            const $dot = $('<div class="carousel-dot"></div>');
            $dot.attr('data-dot-index', i);
            carouselDotsContainer.append($dot);
        }
    }

    const carouselDots = carousel.find('.carousel-dot');

    //add clone first and last card
    if (allCards.length > 8) {
        let allCardsArray = Object.values(allCards);
        const firstElement = $(allCardsArray[0]).clone()
        const lastElement = $(allCardsArray[allCardsArray.length - 1]).clone()

        cards.append(firstElement);
        cards.prepend(lastElement);
        allCards = item.querySelectorAll('.carousel-card-container')

    }


    updateCarousel();

    prevBtn.hover(
        function () {
            if (allCards.length >= 4) {
                $(this).css({opacity: 0.6, cursor: 'pointer'});
            }
        },
        function () {
            if (allCards.length >= 4) {
                $(this).css('opacity', 0);
            }
        }
    )

    prevBtn.on('click', function () {
        if (currentIndex > 0) {
            currentIndex -= 1;
            updateCarousel();
        }
    });

    nextBtn.hover(
        function () {
            if (allCards.length >= 4) {
                $(this).css({opacity: 0.6, cursor: 'pointer'});
            }
        },
        function () {
            if (allCards.length >= 4) {
                $(this).css('opacity', 0);
            }
        }
    )

    nextBtn.on('click', function () {
        if (currentIndex <= allCards.length - 2) {
            currentIndex += 1;
            updateCarousel();
        }
    });


    //Dots navigate
    carouselDots.on('click', function () {
        let scope = (allCards.length / 3).toFixed()
        let currentDotIndex = $(this).data('dot-index') + 1;

        if (currentDotIndex === 3 && allCards.length < 20 && allCards.length > 8) {
            currentIndex = allCards.length - 4
        } else if (currentDotIndex === 1 && allCards.length < 20 && allCards.length > 8) {
            currentIndex = 3
        } else if (currentDotIndex === 3 && allCards.length <= 8) {
            currentIndex = allCards.length - 1
        } else if (currentDotIndex === 1 && allCards.length <= 8) {
            currentIndex = 0
        } else {
            currentIndex = Math.floor(currentDotIndex * scope) - Math.floor(scope / 2)
        }


        $(this).addClass('active-dot')
        updateCarousel();
    });

    //Swipe cards on monile view
    let isDragging = false
    let startPos = 0
    let currentTranslate = 0
    let prevTranslate = 0


    allCards.forEach((card, index) => {
        addEventListener('dragstart', (e) => e.preventDefault())
        // Touch events
        //card.addEventListener('click', () => handleClickSelectCard(index))
        card.addEventListener('touchstart', touchStart(index))
        card.addEventListener('touchend', touchEnd)
        card.addEventListener('touchmove', touchMove)
    })

    function touchStart(index) {
        return function (event) {
            currentIndex = index
            startPos = event.type.includes('pointer') ? event.pageX : event.touches[0].clientX
            isDragging = true
        }
    }

    function touchMove(event) {
        if (isDragging) {
            const currentPosition = event.type.includes('pointer') ? event.pageX : event.touches[0].clientX
            currentTranslate = direction ? prevTranslate + currentPosition - startPos : startPos + (prevTranslate - currentPosition)
        }
    }

    function touchEnd() {
        isDragging = false
        const movedBy = currentTranslate - prevTranslate

        if (movedBy > 100 && currentIndex > 0) {
            currentIndex -= 1;
            updateCarousel()
        }

        if (movedBy < 100 && currentIndex <= allCards.length) {
            currentIndex += 1;
            updateCarousel()
        }
    }


    //update dot
    function findDotForIndex() {
        let scopes = 3;
        let elementsPerScope = (allCards.length / scopes).toFixed()
        for (let i = 0; i < scopes; i++) {
            let start = i * elementsPerScope;
            let end = (i + 1) * elementsPerScope - 1;

            if (currentIndex >= start && currentIndex <= end) {
                return i
            }
        }
    }

    //loop carousel
    $(track).on('transitionend', function () {
        if (bodyWidth <= 640) {
            if (currentIndex <= 0) {
                currentIndex = allCards.length - 2
                let translateX = (containerWidth / 2) - (cardWidth / 2) - (currentIndex * cardWidth)

                cards.css('transition', 'none');
                cards.css('transform', `translateX(${direction ? translateX : -translateX}px)`);
                setTimeout(function () {
                    cards.css('transition', '');
                }, 300);
            }
            if (currentIndex === allCards.length - 1 || currentIndex >= allCards.length) {
                currentIndex = 1
                let translateX = (containerWidth / 2) - (cardWidth / 2) - (currentIndex * cardWidth)

                cards.css('transition', 'none');
                cards.css('transform', `translateX(${direction ? translateX : -translateX}px)`);
                setTimeout(function () {
                    cards.css('transition', '');
                }, 300);
            }
        } else {
            if (allCards.length > 8) {
                if (currentIndex <= 2) {
                    currentIndex = allCards.length - 3;
                    let translateX = (containerWidth / 2) - (cardWidth / 2) - (currentIndex * cardWidth)
                    cards.css('transition', 'none');
                    cards.css('transform', `translateX(${direction ? translateX : -translateX}px)`);
                    setTimeout(function () {
                        cards.css('transition', '');
                    }, 300);
                }
                if (currentIndex >= allCards.length - 2) {
                    currentIndex = 3;
                    let translateX = (containerWidth / 2) - (cardWidth / 2) - (currentIndex * cardWidth)
                    cards.css('transition', 'none');
                    cards.css('transform', `translateX(${direction ? translateX : -translateX}px)`);
                    setTimeout(function () {
                        cards.css('transition', '');
                    }, 300);
                }
            }
        }
    })

    function updateCarousel() {

        translateX = (containerWidth / 2) - (cardWidth / 2) - (currentIndex * cardWidth);

        // change direction for RTL
        if (!direction) {
            translateX = -translateX
        }

        cards.css('transform', 'translateX(' + translateX + 'px)');

        carouselDots.each(function (index) {
            if (index === findDotForIndex()) {
                $(this).addClass('active-dot');
            } else {
                $(this).removeClass('active-dot');
            }
        });
    }
}

window.initCarousel = initCarousel;

$(document).ready(function () {
    $('.carousel:not(.do-not-init)').each(initCarousel)
})
