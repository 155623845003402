var docList = [];

function allowDrop(event){
    event.preventDefault();
}

function drop(event){
    event.preventDefault();
    addUrlnError(event.dataTransfer.files);
}

function addFile(event){
    addUrlnError(event.target.files);
}

$('#file-upload-start').change(function(event){addUrlnError(event.target.files);})
$('#file_upload').change(function(event){addUrlnError(event.target.files);})
$("#startPage").on("dragover", function(event) {
    event.preventDefault();  
});

$("#startPage").on("drop", function(event) {
    event.preventDefault();  
    addUrlnError(event.originalEvent.dataTransfer.files);
});


function addUrlnError(filesObj){
    Object.keys(filesObj).forEach((i) => {
        
        let error = ''
        let fileExtension = filesObj[i].name.split('.').slice(-1)[0].toLowerCase();
        if( (['jpeg','jpg','png'].indexOf(fileExtension) === -1) || ((filesObj[i].size/1024) > 500) ){
            error = '! Error message';
        }
        
        filesObj[i].error = error;
      docList.push(filesObj[i]);
    });
    $('#startPage').hide();
    $('#listingPage').show();
    renderList();
    //$("#startPage").css("display", "none");
}


function removeAndRerender(index){
    docList.splice(index,1);
    if(docList.length>0){
        renderList();
    }else{
        initState();
    }
    
}

function initState(){
    $('#startPage').show(); 
    $('#listingPage').hide();
}

$("#listHolder").on("click",function(event){
console.log('ss_',event);
    let closeId = event.target.id;
    console.log("closeId",closeId);
    let index = Number( closeId.charAt(closeId.length-1) );
    console.log("index",index);
    console.log("3",closeId.substr(0,closeId.length-1), closeId.substr(0,closeId.length-1)==='closeDoc');
    if(closeId.substr(0,closeId.length-1) === 'closeDoc'){
        removeAndRerender(index);
    }
});

$("#refreshCard").on("click",function(event){
    docList = [];
    initState();
});

$("#saveUploadBtn").on("click", function(event){
    $('#listHolder').empty();
    $("#saveUploadBtn").attr("disabled", true);
    for(let i=0;i<docList.length;i++){
        let child = '<div id="document'+i+'" ><div  class="row"> <div class="col-10" >'+docList[i].name+'</div><div class="col-2 " ><i  class="ds-trash-2 curPointer"  ></i></div></div><hr/></div>';
        
        $('#listHolder').append(
        child
        );
      
    }
});

function renderList(){
    $('#listHolder').empty();
    $("#saveUploadBtn").attr("disabled", false);
    if($("html").attr("dir") == "ltr"){

        //english version for 'uploading' and error text
        for(let i=0;i<docList.length;i++){
            let uploadOrErrorText = docList[i].error.length>0?docList[i].error:'Uploading...';
            let child = '<div id="document'+i+'" ><div  class="row"> <div class="col-10" >'+docList[i].name+'</div><div class="col-2 " ><i id="closeDoc'+i+'" class="ds-x-circle curPointer"  ></i></div></div><div class="row"> <div class="col-12"> <div class="progress"> <div id="progressBar'+i+'" class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div></div></div><div id="status'+i+'" class="row greyTxt font14"> <div class="col-8  " >'+uploadOrErrorText+'</div><div class="col-2 offset-2  " >25%</div></div><hr/></div>';
            
            $('#listHolder').append(
            child
            );
            if(docList[i].error.length > 0){
                $('#document'+i).addClass('error');
                $('#closeDoc'+i).addClass('error');
                $('#status'+i).addClass('error');
                $('#progressBar'+i).addClass('bg-danger');
                $("#saveUploadBtn").attr("disabled", true);
            }
        }

    }else{

        //arabic version for 'uploading' and error text
        for(let i=0;i<docList.length;i++){
            let uploadOrErrorText = docList[i].error.length>0?'إشعار خطأ':'جاري التحميل...';
            let child = '<div id="document'+i+'" ><div  class="row"> <div class="col-10" >'+docList[i].name+'</div><div class="col-2 " ><i id="closeDoc'+i+'" class="ds-x-circle curPointer"  ></i></div></div><div class="row"> <div class="col-12"> <div class="progress"> <div id="progressBar'+i+'" class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div></div></div><div id="status'+i+'" class="row greyTxt font14"> <div class="col-8  " >'+uploadOrErrorText+'</div><div class="col-2 offset-2  " >25%</div></div><hr/></div>';
            
            $('#listHolder').append(
            child
            );
            if(docList[i].error.length > 0){
                $('#document'+i).addClass('error');
                $('#closeDoc'+i).addClass('error');
                $('#status'+i).addClass('error');
                $('#progressBar'+i).addClass('bg-danger');
                $("#saveUploadBtn").attr("disabled", true);
            }
        }

    }
    
    
    
}

