$(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() < $(document).height() - $("#footer").height()) {
       
        $('#chatbot').css("position","fixed");    //resetting it
        $('#chatbot').css("bottom","30px"); //resetting it
  }
        if($(window).scrollTop() + $(window).height() > $(document).height() - $("#footer").height()) {
          
        $('#chatbot').css("position","relative"); // make it related
        $('#chatbot').css("bottom","90px"); // 60 px, height of #toTop
  }
    });


    // close an open the chatbot 
    
    $('#chatbot').on('click', function() {
      openForm()
    })
    
    $('#close-chatbot').on('click', function() {
      closeForm()
    })
    
    function openForm() {
      document.getElementById("myForm").style.display = "block";
    }
    
    function closeForm() {
      document.getElementById("myForm").style.display = "none";
    }