$(document).ready(function () {

    $(".ds-filters-check-all").click(function () {
        const name = $(this).data("name");
        $(`input[name="${name}"]`).prop('checked', true);
    });

    $(".ds-filters-uncheck-all").click(function () {
        const name = $(this).data("name");
        $(`input[name="${name}"]`).prop('checked', false);
    });


    $(document).on("click", ".ds-filters-header,.ds-sort-header", function (event) {
        if ($(this).hasClass("active")) {
            $(this)
                .next()
                .addClass("d-none");
            $(this).removeClass("active");

            $(this)
                .next()
                .children()
                .children()
                .removeClass("active");
            // $(this)
            //   .next()
            //   .children()
            //   .children()
            //   .next()
            //   .css("display", "none");
            if ($("html").attr("dir") == "ltr") {
                $("#text-search-filters").text('Search filters');
            } else {
                $("#text-search-filters").text('تصفية نتائج البحث');
            }
        } else {
            $(".ds-filters-header,.ds-sort-header")
                .next()
                .addClass("d-none");
            $(this)
                .next()
                .removeClass("d-none");
            $(".ds-filters-header,.ds-sort-header").removeClass("active");
            $(this).addClass("active");
            if ($("html").attr("dir") == "ltr") {
                $(this).hasClass("ds-filters-header") ? $("#text-search-filters").text('Close filters') : $("#text-search-filters").text('Search filters');
            } else {
                $(this).hasClass("ds-filters-header") ? $("#text-search-filters").text('تصفية نتائج البحث') : $("#text-search-filters").text('تصفية نتائج البحث');
            }

        }
    });

    $(document).on("mousedown", ".ds-filters-header,.ds-sort-header", function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
    });


    $(document).on("click", function (event) {
        if (!$(event.target).closest(".ds-filters,.ds-sort").length) {
            $(".ds-filters-header,.ds-sort-header")
                .next()
                .addClass("d-none");
            $(".ds-filters-header,.ds-sort-header").removeClass("active");
            if ($("html").attr("dir") == "ltr") {
                $("#text-search-filters").text('Search filters');
            } else {
                $("#text-search-filters").text('تصفية نتائج البحث');
            }
        }
    });

    $(document).on("click", ".ds-filters-card-header", function () {
        if ($(this).hasClass("active")) {
            $(this)
                .next()
                .slideUp(400);
            $(this).removeClass("active");
        } else {
            $(".ds-filters-card-header").removeClass("active");
            $(".ds-filters-card-header").next().slideUp();
            $(this)
                .next()
                .slideDown(400);
            $(this).addClass("active");
        }
    });

    // $(document).mouseup(function(e) {
    //   var container = $(".ds-filters");

    //   if (!container.is(e.target) && container.has(e.target).length === 0) {
    //     container
    //       .children()
    //       .next()
    //       .addClass("d-none");

    //     //container.children().removeClass("active").trigger('classChange');
    //     //$(".ds-filters-card-header").removeClass("active");

    //     $(".ds-filters-card-body").css({
    //       display: "none"
    //     });
    //   }
    // });


    // $('#btn-search-filters-mobile').on('click', function () {
    //     $('.section-filter').addClass('active');
    //     $('body').addClass('overflow-h');
    //     $('.section-filter .ds-filters-mobile').append($(".ds-filters-content").html());
    //     $('.section-filter .ds-sort-mobile').append($(".ds-sort-content").html());
    //     $('.ds-filters-content').html('');
    //     $('.ds-sort-content').html('');
    // });
    // $('.ds-x-circle').on('click', function () {
    //     $('.section-filter').removeClass('active');
    //     $('body').removeClass('overflow-h');
    // });


});
