$(document).ready(function () {
    const SHOW_BTN_AFTER = 4;

    $(window).scroll(function () {
        const showThreshold = SHOW_BTN_AFTER * $(window).height();

        if ($(window).scrollTop() >= showThreshold) {
            $("#bckTop").fadeIn();
        } else {
            $("#bckTop").fadeOut();
        }

        /*
            if ($(window).scrollTop() + $(window).height() < $(document).height() - $(".ds-footer-container").height()) {
                $('#bckTop').css("position", "fixed");    //resetting it
                $('#bckTop').css("bottom", "30px"); //resetting it
            }
        */
        /*
            if ($(window).scrollTop() + $(window).height() > $(document).height() - $(".ds-footer-container").height()) {
                $('#bckTop').css("position", "relative"); // make it related
                $('#bckTop').css("bottom", "-60px"); // 60 px, height of #toTop
            }
        */

    });

    $("#bckTop").on('click', function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });

});
