$(document).ready(function () {
/*
    // no longer requires, use css property scroll-margin-top instead
    if ($(window).width() > 767) {
        const stickyOffset = $("body").hasClass("sticky-to-top") ? 75 : 0;
        $(".ds-sideNavigation-content li a").click(function () {
            $("html, body").animate({scrollTop: $($(this).attr("href")).offset().top - stickyOffset}, 10);
            return true;
        });
    }
*/

    // run always
    $(window).bind("scroll", function () {

        let currentTop = $(window).scrollTop();
        // add offset if sticky header
        if ($("body").hasClass("sticky-to-top")) {
            // currentTop += 80; // 5 extra for margins
            // correct for margins and sticky headers
            currentTop += ($(window).width() > 767) ? 80 : 210;
        }

        const elems = $("section");
        elems.each(function (index) {
            // proceed only if has id
            const id = $(this).attr("id");
            if (id) {
                const elemTop = $(this).offset().top;
                let height = Math.round($(this).height());
                if (height === 0) {
                    if (elems[index + 1]) {
                        // get top of next element
                        let nexElemTop = $(elems[index + 1]).offset().top;
                        height = nexElemTop - elemTop;
                    } else {
                        // last element, take height of document
                        let dh = $(document).height();
                        height = dh - elemTop;
                    }
                }
                const elemBottom = elemTop + height;
                if (currentTop >= elemTop && currentTop <= elemBottom) {
                    const navElem = $('a[href="#' + id + '"]');
                    const hasClassActive = navElem.parent().hasClass("active");
                    if (!hasClassActive) {
                        navElem.parent().addClass("active").siblings().removeClass("active");
                        const title = navElem.parent().text()
                        $('#Selected-nav-lable').text(title)
                    }
                }
            }
        });
    });

    $("html").css({scrollBehavior: "smooth"});
});
